/*!
 * Start Bootstrap - New Age v3.3.7 (http://startbootstrap.com/template-overviews/new-age)
 * Copyright 2013-2016 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap/blob/gh-pages/LICENSE)
 */
@import 'vars';
@import 'helpers';
@import 'cookieconsent';

.heading-font {
  font-family: $headingFont, 'Verdana', 'Arial', 'sans-serif';
  font-weight: 200;
  letter-spacing: 1px;
}
.body-font {
  font-family: $bodyFont, 'Verdana', 'Arial', 'sans-serif';
}
.alt-font {
  font-family: $bodyFont, 'Verdana', 'Arial', 'sans-serif';
  text-transform: uppercase;
  letter-spacing: 2px;
}
html,
body {
  height: 100%;
  width: 100%;
}
body {
  font-family: $bodyFont, 'Verdana', 'Arial', 'sans-serif';
}
a {
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  transition: all 0.35s;
  color: $callToActionRed;
}
a:hover,
a:focus {
  color: $callToActionRed;
}
hr {
  max-width: 100px;
  margin: 25px auto 0;
  border-width: 1px;
  border-color: rgba(34, 34, 34, 0.1);
}
hr.light {
  border-color: white;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $headingFont, 'Verdana', 'Arial', 'sans-serif';
  font-weight: 200;
  letter-spacing: 1px;
}
p {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 20px;
}
b {
  font-weight: bold;
}
.navbar-default {
  background-color: white;
  border-color: rgba(34, 34, 34, 0.05);
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  transition: all 0.35s;
  font-family: 'Catamaran', 'Verdana', 'Arial', 'sans-serif';
  font-weight: 200;
  letter-spacing: 1px;
}

.navbar {
  &.affix {
    .navbar-brand > img {
      height: 100%;
      width: auto;
      opacity: 1 !important;
    }
  }
  &.affix-top {
    .navbar-brand {
      display: none;
      opacity: 0;
    }
  }
}

.navbar-default .navbar-header .navbar-toggle {
  font-size: 12px;
  color: #222222;
  padding: 8px 10px;
}
.navbar-default .nav > li > a {
  font-family: $bodyFont, 'Verdana', 'Arial', 'sans-serif';
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 11px;
}
.navbar-default .nav > li > a,
.navbar-default .nav > li > a:focus {
  color: #222222;
}
.navbar-default .nav > li > a:hover,
.navbar-default .nav > li > a:focus:hover {
  color: $callToActionRed;
}
.navbar-default .nav > li.active > a,
.navbar-default .nav > li.active > a:focus {
  color: $callToActionRed !important;
  background-color: transparent;
}
.navbar-default .nav > li.active > a:hover,
.navbar-default .nav > li.active > a:focus:hover {
  background-color: transparent;
}
@media (min-width: 768px) {
  .navbar-default {
    background-color: transparent;
    border-color: transparent;
  }
  .navbar-default .navbar-header .navbar-brand {
    color: rgba(255, 255, 255, 0.7);
  }
  .navbar-default .navbar-header .navbar-brand:hover,
  .navbar-default .navbar-header .navbar-brand:focus {
    color: white;
  }
  .navbar-default .nav > li > a,
  .navbar-default .nav > li > a:focus {
    color: rgba(255, 255, 255, 0.7);
  }
  .navbar-default .nav > li > a:hover,
  .navbar-default .nav > li > a:focus:hover {
    color: white;
  }
  .navbar-default.affix {
    background-color: white;
    border-color: rgba(34, 34, 34, 0.1);
  }
  .navbar-default.affix .navbar-header .navbar-brand {
    color: #222222;
  }
  .navbar-default.affix .navbar-header .navbar-brand:hover,
  .navbar-default.affix .navbar-header .navbar-brand:focus {
    color: $callToActionRed;
  }
  .navbar-default.affix .nav > li > a,
  .navbar-default.affix .nav > li > a:focus {
    color: #222222;
  }
  .navbar-default.affix .nav > li > a:hover,
  .navbar-default.affix .nav > li > a:focus:hover {
    color: $callToActionRed;
  }
}
header {
  position: relative;
  width: 100%;
  min-height: auto;
  overflow-y: hidden;
  background: url('../img/back.jpg'), #7b4397;
  /* fallback for old browsers */
  background: url('../img/back.jpg'),
    -webkit-linear-gradient(to left, #0096a6, #0b3940);
  /* Chrome 10-25, Safari 5.1-6 */
  background: url('../img/back.jpg'), linear-gradient(to left, #0b3940, #0096a6);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background-size: cover;
  color: white;
}
header .header-content {
  text-align: center;
  padding: 150px 0 50px;
  position: relative;
}
header .header-content .header-content-inner {
  position: relative;
  max-width: 500px;
  margin: 0 auto;
}
header .header-content .header-content-inner h1 {
  margin-top: 0;
  margin-bottom: 30px;
  font-size: 30px;
}
header .header-content .header-content-inner .list-badges {
  margin-bottom: 25px;
}
header .header-content .header-content-inner .list-badges img {
  height: 50px;
  margin-bottom: 25px;
}
header .device-container {
  max-width: 300px;
  margin: 0 auto 100px;
}
header .device-container .screen img {
  border-radius: 3px;
}
@media (min-width: 768px) {
  header {
    min-height: 100%;
  }
  header .header-content {
    text-align: left;
    padding: 0;
    height: 100vh;
  }
  header .header-content .header-content-inner {
    max-width: none;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  header .header-content .header-content-inner h1 {
    font-size: 35px;
  }
  header .device-container {
    max-width: none;
    max-height: calc(0vh);
    margin: 100px auto 0;
  }
}
@media (min-width: 992px) {
  header .header-content .header-content-inner h1 {
    font-size: 50px;
  }
}
// section {
//   padding: 100px 0;
// }
section h2 {
  font-size: 50px;
}
section.download {
  padding: 150px 0;
  position: relative;
}
section.download h2 {
  margin-top: 0;
  font-size: 50px;
}
section.download .badges .badge-link {
  display: block;
  margin-bottom: 25px;
}
section.download .badges .badge-link:last-child {
  margin-bottom: 0;
}
section.download .badges .badge-link img {
  height: 60px;
}
@media (min-width: 768px) {
  section.download .badges .badge-link {
    display: inline-block;
    margin-bottom: 0;
  }
}
@media (min-width: 768px) {
  section.download h2 {
    font-size: 70px;
  }
}
section.features .section-heading {
  margin-bottom: 100px;
  margin-top: 40px;
}
section.features .section-heading h2 {
  margin-top: 0;
  font-size: 26pt;
}
section.features .section-heading p {
  margin-bottom: 0;
}
section.features .device-container,
section.features .feature-item {
  max-width: 300px;
  margin: 0 auto;
}
section.features .device-container {
  margin-bottom: 40px;
}
@media (min-width: 992px) {
  section.features .device-container {
    margin-bottom: 0;
  }
}
section.features .feature-item {
  text-align: center;
  margin-bottom: 50px;
}
section.features .feature-item i {
  font-size: 80px;
  background: -webkit-linear-gradient(to left, #7b4397, #dc2430);
  background: linear-gradient(to left, #7b4397, #dc2430);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media (min-width: 992px) {
  section.features .device-container,
  section.features .feature-item {
    max-width: none;
  }
}
section.cta {
  position: relative;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
  background-position: center;
  background-image: url('../img/bg-cta.jpg');
  padding: 250px 0;
}
section .cta-content {
  position: relative;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
}
section.cta .cta-content h2 {
  margin-top: 0;
  margin-bottom: 25px;
  color: white;
  max-width: 450px;
  font-size: 50px;
}
@media (min-width: 768px) {
  section.cta .cta-content h2 {
    font-size: 80px;
  }
}
section.cta .overlay {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
}
section.contact {
  text-align: center;
}
section.contact h2 {
  margin-top: 0;
  margin-bottom: 25px;
}
section.contact h2 i {
  color: #dd4b39;
}
section.contact ul.list-social {
  margin-bottom: 0;
}
section.contact ul.list-social li a {
  display: block;
  height: 80px;
  width: 80px;
  line-height: 80px;
  font-size: 40px;
  border-radius: 100%;
  color: white;
}
section.contact ul.list-social li.social-twitter a {
  background-color: #1da1f2;
}
section.contact ul.list-social li.social-twitter a:hover {
  background-color: #0d95e8;
}
section.contact ul.list-social li.social-facebook a {
  background-color: #3b5998;
}
section.contact ul.list-social li.social-facebook a:hover {
  background-color: #344e86;
}
section.contact ul.list-social li.social-google-plus a {
  background-color: #dd4b39;
}
section.contact ul.list-social li.social-google-plus a:hover {
  background-color: #d73925;
}
footer {
  background-color: #0b3940;
  padding: 25px 0;
  text-align: center;
}
footer li {
  color: rgba(255, 255, 255, 0.5);
}
footer p {
  font-size: 12px;
  margin: 0;
  color: rgba(255, 255, 255, 0.5);
}
footer ul {
  margin-bottom: 10px;
}
footer ul li a {
  font-size: 15px;
  color: rgba(255, 255, 255, 0.8);
}
footer ul li a:hover,
footer ul li a:focus,
footer ul li a:active,
footer ul li a.active {
  text-decoration: none;
}
.bg-primary {
  background: $callToActionRed;
  background: -webkit-linear-gradient($callToActionRed, #fdc539);
  background: linear-gradient($callToActionRed, #fdc539);
}
.text-primary {
  color: $callToActionRed;
}
.no-gutter > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}
.btn-outline {
  color: white;
  border-color: white;
  border: 1px solid;
}
.btn-outline:hover,
.btn-outline:focus,
.btn-outline:active,
.btn-outline.active {
  color: white;
  background-color: $callToActionRed;
  border-color: $callToActionRed;
}
.btn {
  font-family: $bodyFont, 'Verdana', 'Arial', 'sans-serif';
  text-transform: uppercase;
  letter-spacing: 2px;
  border-radius: 300px;
}
.btn-xl {
  padding: 15px 45px;
  font-size: 11px;
}
::-moz-selection {
  color: white;
  text-shadow: none;
  background: #222222;
}
::selection {
  color: white;
  text-shadow: none;
  background: #222222;
}
img::selection {
  color: white;
  background: transparent;
}
img::-moz-selection {
  color: white;
  background: transparent;
}
body {
  webkit-tap-highlight-color: #222222;
}

#videoContact {
  color: white;
}

.bigHero {
  padding: 80px 0 80px 0;
  background-color: #0b3940;
  color: white;

  h1, p {
    margin-bottom: 0;
  }
}
